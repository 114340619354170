/* src/styles/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Define theme classes for body */
body.theme-light {
    background-color: #f9fafb; /* light background */
    color: #064e3b;
  }
  
  body.theme-dark {
    background-color: #000000; /* dark background */
    color: #ffffff;
  }
  
  body.theme-sunset {
    background-color: #1a202c; /* sunset background */
    color: #64ffda;
  }
